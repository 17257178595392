
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Press+Start+2P&family=Rajdhani&display=swap');
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #E07A5F;
  animation: change-color-anim 10s linear infinite;
}


@keyframes change-color-anim {
  0%,100%{
    background-color: #E07A5F;
  }
  25%{
    background-color: #F2CC8F;
  }
  50%{
    background-color: #81b29a;
  }
  50%{
    background-color: #1d3557;
  }
  
}

.dialog{
    background-color: #3d405b;
    position: relative;
    z-index: 1;
    color: white;
    width: 80%;
    height: 90vh;
    border-radius: 10px;
    opacity: 80%;
    box-shadow: 3px 3px 0px 3px #f2cc8f;
    @media only screen and (max-width: 600px) {
      height: 60vh;
    }
  }

  .title{
    margin-top: 20px;
    font-size:5vw;
    font-family: 'Press Start 2P', cursive;
    transition: all .2s ease-in-out;
  }
  /* .title:hover{
    transform: scale(1.1);
  } */

  .sub-title{
    margin-top: 3%;
    font-size:3.5vw;
    color: #3d405b;
    font-family: 'Rajdhani', cursive;
      background: url(https://s2.svgbox.net/pen-brushes.svg?ic=brush-2&color=ffffff);
      transition: all .2s ease-in-out;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  /* .sub-title:hover{
    transform: scale(1.1);
  }
   */
  .tags{
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 4%; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vh;
    height: 5vh;
    border-radius: 5px;
    transition: all .2s ease-in-out;

  }
  /* .tags:hover{
    transform: scale(1.1);
  } */
  .tags-group{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2vh;
  }

  .tags-group > div {
    flex: 15%;
    text-align: center;
  }

  .tags-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5vw;
    margin-right: 2px;
    margin-left: 5%;
  }
  .tags-text{
    font-size: 2.2vw;
    font-family: 'Rajdhani';
    font-weight: bold;
    
  }

  .orange{
    box-shadow: 3px 3px 0px 3px #f2cc8f;
    border: 1px #f2cc8f solid;
    /* background-color: #d08c60; */
  }

  .blue{
    box-shadow: 3px 3px 0px 3px #0081a7;
    border: 1px #0081a7 solid;
  }

  .green{
    box-shadow: 3px 3px 0px 3px #81b29a;
    border: 1px #81b29a solid;
  }

  .blogs{
    margin-top: 4vh;
    font-size:3vw;
    font-family: 'Dancing Script', cursive;
  }

  .blog-link{
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 4%; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3d405b;
    width: 12vh;
    height: 5vh;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    font-size:2.5vw;
    background: url(https://s2.svgbox.net/pen-brushes.svg?ic=brush-8&color=ffffff);
    font-family: 'Rajdhani';
    cursor: pointer;
    text-decoration: none;
  }
  .blog-link:hover{
    transform: scale(1.1);
  }
  .tags-group > a {
    flex: 15%;
  }

  .contact{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1%;
  }

  .contact-link{
    margin-top: 2%;
    /* margin-left: 3%;
    margin-right: 4%;  */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3d405b;
    width: 12vh;
    height: 5vh;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    font-size:2.5vw;
    background: url(https://s2.svgbox.net/pen-brushes.svg?ic=brush-2&color=ffffff);
    font-family: 'Rajdhani';
    text-decoration: none;
    width: 40%;
  }
  .contact-group{
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .blog-link:hover{
    transform: scale(1.1);
  }
  .tags-group > a {
    flex: 15%;
  }
